import { render, staticRenderFns } from "./delayed.vue?vue&type=template&id=008681a4&scoped=true"
import script from "./delayed.vue?vue&type=script&lang=js"
export * from "./delayed.vue?vue&type=script&lang=js"
import style0 from "./delayed.vue?vue&type=style&index=0&id=008681a4&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "008681a4",
  null
  
)

export default component.exports