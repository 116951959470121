<template>
  <div class="stage">
    <Search :searchOptions="options" @search="getSearch"> </Search>
    <div class="stage_content">
      <Table
        :tableOptions="tableOptions"
        :tableData="tableData"
        :page="page"
        :total="total"
        @update:page="updatePage"
      >
        <template v-slot:action="{ scope }">
          <div class="btns">
            <el-button type="text" @click="look('look', scope)">查看</el-button
            ><el-button
              type="text"
              @click="look('edit', scope)"
              :disabled="scope.reservationStatus == 1"
              >编辑</el-button
            ><el-button
              type="text"
              style="color: red"
              v-if="scope.reservationStatus == 0"
              size="small"
              @click="handleConfirm(scope)"
              >办理人员确认</el-button
            >
          </div>
        </template>
      </Table>
    </div>
    <!-- 弹窗表单 -->
    <el-dialog
      title="类型配置"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
      destroy-on-close
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        :disabled="mode == 'look' ? true : false"
      >
        <el-form-item label="预约日期" prop="reservationDate">
          <el-date-picker
            v-model="ruleForm.reservationDate"
            :disabled="ruleForm.reservationStatus == 1"
            type="date"
            placeholder="请选择时间"
            @change="handlePicker"
          />
        </el-form-item>
        <el-form-item label="预计办理时间" prop="reservationStartTime">
          <el-time-select
            v-model="ruleForm.reservationStartTime"
            :picker-options="{
              start: '17:00',
              step: '00:30',
              end: '20:00',
            }"
            placeholder="选择时间"
          >
          </el-time-select>
        </el-form-item>
        <el-form-item label="内容(不可修改)">
          <el-input
            v-model="ruleForm.reservationContent"
            disabled
            :rows="5"
            type="textarea"
            maxlength="300"
            show-word-limit
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button
          type="primary"
          :disabled="mode == 'look' ? true : false"
          @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 、 -->
    <el-dialog
      :visible.sync="isShow"
      width="30%"
      title="办理人员确认"
      :before-close="handleTransactionClose"
    >
      <el-form
        ref="rulesForm"
        :model="Form"
        :rules="rules"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="办理人员" prop="handleName">
          <el-input v-model="Form.handleName" placeholder="请输入办理人员" />
        </el-form-item>
        <el-form-item label="办理地点" prop="handleAddress">
          <el-input v-model="Form.handleAddress" placeholder="请输入办理地点" />
        </el-form-item>
        <el-form-item label="所需资料" prop="requiredInfo">
          <el-checkbox-group v-model="checkList" @change="handleCheck">
            <el-checkbox
              v-for="item in reservation"
              :key="item.id"
              :label="item.replayName"
              :value="item.id"
            />
          </el-checkbox-group>
          <el-input
            v-model="Form.requiredInfo"
            :rows="5"
            type="textarea"
            show-word-limit
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleTransactionClose">取 消</el-button>
        <el-button
          type="primary"
          :disabled="mode == 'look' ? true : false"
          @click="submitTransaction('rulesForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Search from "../../components/Search.vue";
import Table from "../../components/Table.vue";
export default {
  components: {
    Search,
    Table,
  },
  data() {
    return {
      reservationStart: "",
      reservationEnd: "",
      dialogVisible: false,
      isShow: false,
      reservation: [],
      checkList: [],
      mode: "add",
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      options: [
        {
          type: "select",
          placeholder: "状态",
          prop: "status",
          options: [
            {
              label: "预约中",
              value: 0,
            },
            {
              label: "已预约",
              value: 1,
            },
          ],
        },
        {
          type: "date",
          placeholder: "创建时间",
          prop: "createDate",
        },
        {
          type: "time",
          start: "办理开始时间",
          end: "办理结束时间",
          prop: "times",
        },
      ],
      tableData: [],
      tableOptions: [
        {
          label: "预约人",
          prop: "userName",
        },
        {
          label: "联系电话",
          prop: "phone",
        },
        {
          label: "帮他人预约联系方式",
          prop: "otherPhone",
        },
        {
          label: "预计办理时间",
          prop: "reservationDate",
        },
        {
          label: "状态",
          prop: "reservationStatus",
          enum: {
            0: "预约中",
            1: "已预约",
          },
        },
        {
          label: "办理人员",
          prop: "handleName",
        },
        {
          label: "创建时间",
          prop: "createTime",
        },
      ],
      rules: {
        reservationDate: [
          { required: true, message: "请选择日期", trigger: "change" },
        ],
        reservationStartTime: [
          { required: true, message: "请选择开始时间段", trigger: "change" },
        ],
        handleName: [
          { required: true, message: "请输入办理人员", trigger: "blur" },
          { min: 1, max: 50, message: "最多50个字", trigger: "blur" },
        ],
        handleAddress: [
          { required: true, message: "请输入办理地点", trigger: "blur" },
          { min: 1, max: 50, message: "最多50个字", trigger: "blur" },
        ],
        reservationContent: [
          { required: true, message: "请选择所需资料", trigger: "blur" },
        ],
      },
      ruleForm: {
        reservationDate: "",
        reservationStartTime: [],
      },
      Form: { handleName: "", handleAddress: "", requiredInfo: "" },
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async getReservation() {
      const { data: res } = await this.$get(
        "/xng/backed/delayReservation/selectReplyPageListByCondition",
        { pageSize: 100, pageNum: 1 }
      );
      if (res) {
        this.reservation = res.rows;
      }
    },
    look(type, row) {
      this.mode = type;
      this.ruleForm = Object.assign({}, row);
      this.dialogVisible = true;
    },
    getSearch(val) {
      const params = {
        pageNum: 1,
        pageSize: 10,
        ...val,
        reservationStartDate: val.times ? val.times[0] : "",
        reservationEndDate: val.times ? val.times[1] : "",
      };
      delete params.times;
      this.page = params;
      this.loadData();
    },
    // 加载数据
    async loadData() {
      const { flag, data } = await this.$get(
        "/xng/backed/delayReservation/selectPageListByCondition",
        this.page
      );
      if (flag) {
        this.total = data.total;
        this.tableData = data.rows;
      }
    },
    modalShow() {
      this.dialogVisible = true;
    },
    updatePage(val) {
      this.page.pageNum = val;
      this.loadData();
    },
    handleTransactionClose() {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.isShow = false;
          this.Form = {};
          this.checkList = [];
        })
        .catch((_) => {});
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.dialogVisible = false;
          this.ruleForm = {};
          this.mode = "add";
        })
        .catch((_) => {});
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // console.log(this.ruleForm);

          const { flag } = await this.$post(
            "/xng/backed/delayReservation/updateDelayReservation",
            this.ruleForm
          );
          if (flag) {
            this.loadData();
            this.dialogVisible = false;
            this.$message({
              message: "编辑成功",
              type: "success",
            });
          } else {
            this.$message.error("编辑失败");
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    submitTransaction(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // console.log(this.Form);

          const { flag } = await this.$post(
            "/xng/backed/delayReservation/confirmDelayReservation",
            this.Form
          );
          if (flag) {
            this.loadData();
            this.isShow = false;
            this.$message({
              message: "编辑成功",
              type: "success",
            });
          } else {
            this.$message.error("编辑失败");
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handlePicker(val) {
      this.ruleForm.reservationStartTime = "";
      // ruleForm.value.reservationEndTime = ''
      this.getWeek(val);
    },
    // 预约时间段周一至周五，每天18:0 - 20:00;周六，周日每天09:0 - 18:00;
    getWeek(date) {
      let week = new Date(date).getDay();
      if (week == 0 || week == 6) {
        this.reservationStart = "09:00";
        this.reservationEnd = "18:00";
      } else {
        this.reservationStart = "17:00";
        this.reservationEnd = "20:00";
      }
    },
    // 选中类型
    handleCheck(val) {
      let arr = [];
      val.forEach((item) => {
        this.reservation.forEach((item1) => {
          // console.log(item, item1);

          if (item == item1.replayName) {
            arr.push(item1.replayContent);
          }
        });
      });
      console.log(arr);

      this.Form.requiredInfo = arr.join(",");
    },
    handleConfirm(val) {
      console.log(val);

      // console.log('办理人员确认');
      this.Form.id = val.id;
      this.isShow = true;
      this.getReservation();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-form-item__label {
  width: 150px !important;
}
/deep/.el-form-item__content {
  margin-left: 150px !important;
}
.stage {
  width: 100%;
  .btns {
    padding: 0 10px;
  }
  .stage_content {
    width: 100%;
  }
}
</style>
