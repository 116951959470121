<template>
  <div class="table_box">
    <el-table
      :data="tableData"
      style="width: 100%; margin-bottom: 20px"
      fit
      max-height="800"
    >
      <el-table-column type="index" width="50" label="序号" align="center">
      </el-table-column>
      <el-table-column
        v-for="item in tableOptions"
        :key="item.prop"
        :prop="item.prop"
        :label="item.label"
        align="center"
      >
        <template slot-scope="scope">
          <div v-if="item.type == 'img'">
            <img :src="scope.row.url" alt="" />
          </div>

          <div v-else>
            {{
              item.enum
                ? item.enum[scope.row[item.prop]]
                : scope.row[item.prop] || "/"
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" v-if="action">
        <template slot-scope="scope">
          <slot name="action" v-bind:scope="scope.row"></slot>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="pagination"
      @current-change="handleCurrentChange"
      :current-page.sync="page.pageNum"
      :page-size="page.pageSize"
      layout="total, prev, pager, next"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    tableData: Array,
    tableOptions: Array,
    page: Object,
    total: Number,
    action: {
      type: Boolean,
      default: true,
    },
    pagination: { type: Boolean, default: true },
  },
  methods: {
    handleCurrentChange(val) {
      this.$emit("update:page", val);
    },
  },
};
</script>

<style lang="less" scoped>
.table_box {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #fff;
  border-radius: 16px;
  /deep/table {
    width: 100% !important;
  }
  /deep/.el-table__empty-block {
    width: 100% !important;
  }
  img {
    width: 200px;
  }
  /deep/.el-pagination {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
