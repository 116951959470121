<template>
  <div class="search">
    <div class="search_options" v-for="item in searchOptions" :key="item.prop">
      <div class="search_option" v-if="item.type == 'input'">
        <el-input
          style="width: 240px"
          v-model="search[item.prop]"
          :placeholder="
            item.placeholder ? `请输入${item.placeholder}` : '请输入'
          "
        ></el-input>
      </div>
      <div class="search_option" v-if="item.type == 'select'">
        <el-select
          style="width: 240px"
          v-model="search[item.prop]"
          :placeholder="
            item.placeholder ? `请选择${item.placeholder}` : '请选择'
          "
        >
          <el-option
            v-for="item in item.options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="search_option" v-if="item.type == 'date'">
        <el-date-picker
          v-model="search[item.prop]"
          type="date"
          :placeholder="
            item.placeholder ? `请选择${item.placeholder}` : '请选择'
          "
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
      <div class="search_option" v-if="item.type == 'time'">
        <el-date-picker
          v-model="search[item.prop]"
          type="datetimerange"
          range-separator="至"
          :start-placeholder="item.start ? `${item.start}` : '开始时间'"
          :end-placeholder="item.end ? `${item.end}` : '结束时间'"
          value-format="yyyy-MM-dd hh:mm:ss"
        >
        </el-date-picker>
      </div>
    </div>

    <div class="search_btn">
      <el-button class="btn" type="primary" @click="getSearch">搜索</el-button>
      <el-button class="btn" type="primary" @click="reset">重置</el-button>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    searchOptions: Array,
  },
  data() {
    return {
      search: {},
    };
  },
  methods: {
    getSearch() {
      this.$emit("search", this.search);
    },
    reset() {
      this.search = {};
      this.$emit("search", this.search);
    },
  },
};
</script>

<style lang="less" scoped>
.search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  background: #ffffff;
  border-radius: 16px;
  margin-top: 16px;
  margin-bottom: 16px;

  .search_options {
    // width: 80%;
    height: 80px;
    display: flex;
    align-items: center;
    padding: 0 0 0 5px;
  }
  .search_btn {
    width: 80%;
    height: 80px;
    display: flex;
    align-items: center;
    padding: 0 0 0 5px;

    .btn {
      width: 110px;
      height: 40px;
    }
  }
}
</style>
